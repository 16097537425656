import { useState, useEffect, useMemo } from 'react'
import { TicketService } from 'utility'

export const MyTickets = TicketTense => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.myTickets({
                TicketTense,
            })
            setData(result?.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [TicketTense])

    return {
        data,
        error,
        loading,
    }
}

export const GetSponsersTicketByEvent = id => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        ShowAllTickets: false,
        EventId: id,
    })
    const optionList = useMemo(() => {
        return data?.map(ele => ({
            value: ele.EventTicketId,
            label: ele.TicketType,
        }))
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getSponsersTicket(filter)
            setData(result?.Result || [])
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const refetch = () => {
        fetchData()
    }

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }

    return {
        data,
        optionList,
        error,
        loading,
        filterChanged,
        setData,
        refetch,
    }
}

export const GetAllTicketTypeByEvent = id => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const optionList = useMemo(() => {
        return data?.map(ele => ({
            value: ele.EventTicketId,
            label: ele.TicketType,
        }))
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getAllTicketType(id)
            setData(result?.Result || [])
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        optionList,
        error,
        loading,
        setData,
    }
}

export const GetAsignSponsorTicketByEvent = id => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getAsignSponsorTicket(id)
            setData(result?.Result || [])
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetSoldTicketsByType = ProviderEventId => {
    const [data, setData] = useState([])
    const [metaData, setMetaData] = useState({})
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        PageNumber: 1,
        PageSize: 10,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getSoldTicketsByType({
                ProviderEventId,
                ...filter,
            })
            if (filter.PageNumber === 1) {
                setData(result.Result.Items)
                setMetaData({ TotalAmount: result.Result.TotalAmount })
            } else {
                setData([...data, ...result.Result.Items])
                setMetaData({ TotalAmount: result.Result.TotalAmount })
            }
            setHasMore(result.Result.Event.length === filter.PageSize)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    useEffect(() => {
        setFilter({
            PageNumber: 1,
            PageSize: 10,
        })
    }, [])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
            PageNumber: 1,
            PageSize: 10,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
            PageSize: 10,
        })
    }

    return {
        data,
        metaData,
        error,
        loading,
        fetchMoreData,
        filterChanged,
        hasMore,
    }
}

export const GetSoldTicketsByBuyer = ProviderEventId => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        PageNumber: 1,
        PageSize: 10,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TicketService.getSoldTicketsByBuyer({
                ProviderEventId,
                ...filter,
            })
            if (filter.PageNumber === 1) {
                setData(result.Result.Items)
            } else {
                setData([...data, ...result.Result.Items])
            }
            setHasMore(
                result.Result.MetaData.CurrentPage !==
                result.Result.MetaData.LastPage,
            )
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    const checkInOut = async (QRCodeId, isCheckIn = true) => {
        try {
            setLoading(true)
            await TicketService.TicketCheckInOut({
                QRCodeId,
                ChekInMode: 2,
                CheckInOut: isCheckIn ? 1 : 0,
            })
            const newData = data.map(d =>
                d.QRCodeId === QRCodeId ? { ...d, AllCheckedIn: isCheckIn } : d)
            setData(newData)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    useEffect(() => {
        setFilter({
            PageNumber: 1,
            PageSize: 10,
        })
    }, [])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
            PageNumber: 1,
            PageSize: 10,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
        })
    }

    return {
        data,
        error,
        loading,
        fetchMoreData,
        filterChanged,
        checkInOut,
        hasMore,
    }
}