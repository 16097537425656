import { CommonUtility, APIPath } from 'utility'
import { BaseService } from './base'

class RedistributeTicket {
    list(id) {
        return BaseService.get(
            `${APIPath.getredistributeTicket}?redistributionUId=${id}`,
        )
    }

    add(data) {
        return BaseService.post(APIPath.addredistributeTicket, data)
    }

    update(reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.post(APIPath.updateFaq, data)
    }

    remove(id) {
        return BaseService.post(`${APIPath.removeFaq}?UniqueId=${id}`)
    }
}

const RedistributeTicketService = new RedistributeTicket()
Object.freeze(RedistributeTicketService)
export { RedistributeTicketService }
