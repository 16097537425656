import { Divider, Form } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormTextFormField, PrimaryButton } from 'elements'
import { PushNotification } from 'components'
import { NotificationStatus, NotificationText, TeamService } from 'utility'

const VerifyContainer = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 10px;
    width: 360px;
`

const VerifySchema = yup.object().shape({
    SendTo: yup.string().required('Email is required').email(),
    Code: yup.string().required('Code is required'),
})
export const VerifyInviteUsersScreen = () => {
    const [proccessing, setProcessing] = useState('');
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VerifySchema),
    })
    const submit = async (formData) => {
        try {
            setProcessing("proccessing")
            await TeamService.verifyInvitedMember(formData)
            PushNotification(NotificationText.UserVerified, NotificationStatus.success)
        } catch {
            PushNotification(NotificationText.EmailCodeInvalid, NotificationStatus.error)
        } finally {
            setProcessing('')
        }
    }
    return (
        <VerifyContainer className="container p-4 mt-5">
            <h5>Verify Invite User</h5>
            <Divider className="mt-0" />
            <Form className="" initialValues={{ Email: '', Password: '' }}>
                <Form.Item>
                    <FormTextFormField
                        control={control}
                        label="Email Address"
                        name="SendTo"
                        placeholder="Email Address"
                        errors={errors?.SendTo}
                        defaultValue=""
                    />
                </Form.Item>
                <Form.Item className="">
                    <FormTextFormField
                        control={control}
                        label="Code"
                        name="Code"
                        placeholder="Code"
                        errors={errors?.Code}
                        defaultValue=""
                    />
                </Form.Item>
                <Form.Item>
                    <PrimaryButton className="col-12" loading={proccessing} block type="submit" onClick={handleSubmit(submit)}>
                        Verify
                    </PrimaryButton>
                </Form.Item>
            </Form>
        </VerifyContainer>
    )
}
