import { DangerText, FlexBox, FlexColumn, FlexRowBetween, Loader, PopUpModal } from 'components'
import React, { useEffect, useMemo, useState } from 'react'
import { AddEditTableModal } from './modal'
import { SponserTableService } from 'utility'
import { GetSponserTableList } from 'hooks'
import styled from 'styled-components'
import { Form, Table } from 'antd'
import { AppInput, OutlinedButton } from 'elements'

const TableItem = styled.div`
background: ${({ theme }) => theme.colors.secondary};
border-radius: 6px;
.ant-input  {
    background: ${({ theme }) => theme.colors.secondary} !important;
    height: 35px;
    color: ${({ theme }) => theme.colors.white};
}
`
const TableItemBox = ({ item, onAssignTable }) => <TableItem className="mb-3 p-2" key={`table- ${item.TableId}`}>
    <Form>
        <FlexRowBetween className="align-items-center">
            <p className="align-items-center my-0">{item.TableName} ({(item.TableLimit - item.TotalAssigned)} available)</p>
            <FlexColumn className="col-3">
                <AppInput
                    type="number"
                    onChange={(e) => {
                        onAssignTable(item.TableId, e.target.value)
                    }}
                />
            </FlexColumn>
        </FlexRowBetween>
        {Boolean(item.error) && <DangerText>{item.error}</DangerText>}
    </Form>
</TableItem>

export const AssignTableListModal = ({ open, onCancel, onSubmit, eventId, maxTicket }) => {
    const [openTableModel, setOpenTableModel] = useState(false)
    const [error, setError] = useState("")
    const { data: alltableList, setData, loading } = GetSponserTableList(eventId)
    const onTableAddCancel = () => {
        setOpenTableModel(false)
    }
    const onTableAdd = async (data) => {
        try {
            const result = await SponserTableService.add({ ...data, ProviderEventId: eventId })
            setData([...alltableList, result.Result])
            setOpenTableModel(false)
        } catch (error) {
            console.log('Error:', error)
        }
    }
    const onAssignTable = (id, value) => {
        setData(alltableList.map(ele => {
            if (ele.TableId === id) {
                let error = ""
                if (Number(value) < 0) {
                    error = "Please enter valid number"
                }
                if (Number(value) > (ele.TableLimit - ele.TotalAssigned)) {
                    error = "Please enter value in range"
                }
                return {
                    ...ele,
                    error,
                    AssignedCount: Number(value),
                }
            }
            return ({
                ...ele,

            })
        }))
    }
    useEffect(() => {
        let assignedTickets = 0
        alltableList.map(ele => {
            assignedTickets += (ele.AssignedCount || 0)
            return ""
        })
        if (assignedTickets > maxTicket) {
            setError(`Max selection range is exceed, assign tickets between 0 to ${maxTicket}`)
        } else {
            setError("")
        }
    }, [alltableList])
    const onTableSubmit = () => {
        if (error) {
            return
        }
        onSubmit(alltableList.filter(ele => ele.AssignedCount > 0).map(ele => ({ TableId: ele.TableId, AssignedCount: ele.AssignedCount, OldAssignedCount: 0 })))
    }
    return (
        <PopUpModal
            centered
            title="Assign Table"
            open={open}
            onCancel={onCancel}
            onSubmit={
                onTableSubmit
            }
            width={400}
        >
            {loading && <Loader loading={loading} />}
            {
                alltableList.map(ele => <TableItemBox item={ele} onAssignTable={onAssignTable} />)
            }
            {error && <DangerText>{error}</DangerText>}
            <AddEditTableModal open={openTableModel} onSubmit={onTableAdd} onCancel={onTableAddCancel} />
            <FlexBox className="justify-content-end">
                <OutlinedButton onClick={() => setOpenTableModel(true)}> + Add New Table</OutlinedButton>
            </FlexBox>
        </PopUpModal>

    )
}

export const ViewTableList = ({ open, onCancel, loading, data }) => {
    const columns = useMemo(() => [
        {
            title: 'TableLimit',
            dataIndex: 'TableLimit',
            key: 'TableLimit',
        },
        {
            title: 'TotalAssigned',
            dataIndex: 'TotalAssigned',
            key: 'TotalAssigned',
        },
    ], [data])
    return (
        <PopUpModal
            centered
            title="Assign Table"
            open={open}
            onCancel={onCancel}
            onSubmit={onCancel}
            width={400}
        >
            {loading && <Loader loading={loading} />}
            <div className="my-3">
                <Table
                    columns={columns}
                    dataSource={data || []}
                    size="small"
                />
            </div>
        </PopUpModal>

    )
}