import { useState, useEffect } from 'react'
import { PublicSummaryService } from 'utility'

export const GetPublicSummary = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({})

    const fetchData = async () => {
        try {
            setLoading(true)
            if (Object.keys(filter).length > 0) {
                const result = await PublicSummaryService.getPublicSummary(
                    filter,
                )
                setData(result.Result || {})
            }
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [filter])

    const filterChanged = changeValues => {
        setFilter({
            ...changeValues,
        })
    }

    return {
        data,
        error,
        loading,
        filterChanged,
    }
}