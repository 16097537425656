import { useAuth } from 'context'
import { useState, useEffect } from 'react'
import { AssignCouponService } from 'utility'

export const GetAssignCoupon = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { profile } = useAuth()
  const [hasMore, setHasMore] = useState(false)
  const [filter, setFilter] = useState({
    // pageNumber: 1,
    // pageSize: 9,
    createdBy: profile?.Id,
  })

  const fetchData = async () => {
    try {
      setLoading(true)
      if (profile?.Id) {
        const result = await AssignCouponService.getAssignedCoupons({ ...filter, createdBy: profile?.Id })
        if (filter.pageNumber === 1) {
          setData(result.Result.Items)
        } else {
          setData(prevData => [...prevData, ...result.Result.Items])
        }
        setHasMore(result.Result.Items.length === filter.pageSize)
      }
    } catch (error) {
      setError(error.message || 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!loading && profile?.Id) {
      fetchData()
    }
  }, [filter, profile])

  const filterChanged = changeValues => {
    setFilter(prev => ({
      ...prev,
      ...changeValues,
      pageNumber: 1,
    }))
  }

  const fetchMoreData = () => {
    setFilter(prev => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
    }))
  }

  const refetch = () => {
    fetchData()
    // setFilter({
    //   // pageSize: 9,
    //   // pageNumber: 1,
    //   createdBy: profile.Id
    // })
  }

  return {
    data,
    error,
    setData,
    loading,
    fetchMoreData,
    hasMore,
    filterChanged,
    refetch,
  }
}
