import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '../assets/Icons/add.svg'
import styled from 'styled-components'
import MinusIcon from '../assets/Icons/minus.svg'
import '../screens/Css/BuyTicketModal.css'
import { BusinesService, NotificationStatus, NotificationText } from 'utility'
import { GetBusinessCategoryList, useQueryString } from 'hooks'
import { Loader, PopUpModal, PushNotification } from 'components'
import { BrowserUtility } from 'utility/browser-utility'
import { Form, Skeleton } from 'antd'
import { FormTextFormField } from 'elements'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const UserSchema = yup.object().shape({
    CellPhone: yup.string().required('Phone is required'),
    Email: yup.string().email().required('Email is required'),
})
const TicketMain = styled.div`
    padding-top: 50px;
    width: 95%;
    margin: 0 auto 100px;
    .heading-main {
        display: flex;
        margin: 0px auto 20px;
        width: 77%;
        justify-content: space-between;
        align-items: center;
    }
    .heading {
        font-weight: 700;
        font-size: 22px;
        padding-bottom: 10px;
    }
    @media (min-width: 200px) and (max-width: 480px) {
        padding-top: 20px;
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
            .heading {
                font-size: 20px;
            }
        }
    }
    @media (min-width: 481px) and (max-width: 768px) {
        padding-top: 20px;
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
            .heading {
                font-size: 20px;
            }
        }
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        padding-top: 25px;
        .heading-main {
            margin: 0px 45px;
            margin-bottom: 10px;
            .heading {
                font-size: 20px;
            }
        }
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        .heading-main {
            margin: 0px 10px;
            margin-bottom: 10px;
            .heading {
                font-size: 20px;
            }
        }
    }
`
const ModalContainer = styled.div`
    overflow-x: visible;
    width: 90%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 5px;
    // background: rgba(255, 255, 255, 0.1);
    .buy-ticket-section {
        border-radius: 20px;
        margin: 0 auto;
        width: 100%;
        height: 80vh;
        overflow-y: auto;
    }
    @media (min-width: 200px) and (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        width: 90%;
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        width: 100%;
    }
`
const ModalWrapper = styled.div`
    background: #242631;
    border-radius: 14px;
    padding: 13px 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item-name {
        position: relative;
    }
    .img {
        width: 60px;
        border-radius: 5px;
    }
    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 24px;
        left: 0;
        right: 0;
    }
    .item-name:hover .tooltip-text {
        visibility: visible;
    }
`
const OuterBox = styled.div`
    display: flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    align-items: center;
    gap: 12px;
`
const Icon = styled.div`
    width: 34px;
    height: 34px;
    background: rgba(138, 138, 138, 0.3);
    border-radius: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
`
const SoldTicket = styled.div`
    padding: 6px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-width: 110px;
    span {
        color: #ff384e;
    }
`
const AddTicketBtn = styled.div`
    padding: 6px 12px;
    background: #ff384e;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    min-width: 110px;
`
const TotalMain = styled.div`
    background: #242631;
    border-radius: 30px 30px 30px 30px;
    padding: 18px 20px;
    margin: 0 auto;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
`
const TotalFees = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`
const Tag = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
`
const Amount = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    opacity: 0.6;
`
const ServiceFees = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px dashed #8a8a8a48;
`
const TotalAmount = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
`
const Btn = styled.div`
    .back-btn {
        width: 220px;
        margin-right: 10px;
        padding: 12px 12px;
        background: #4a4c5e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
    .next-btn {
        width: 220px;
        margin-left: 10px;
        padding: 12px 12px;
        background: #ff384e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
    @media (min-width: 200px) and (max-width: 768px) {
        .back-btn {
            width: 350px;
            margin-right: 0px;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        .next-btn {
            width: 350px;
            margin-left: 0px;
        }
    }
`
export const QrCartScreen = () => {
    const [items, setItems] = useState({})
    const [loading, setLoading] = useState(false)
    const [openUserModal, setOpenUserModal] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [serviceFees, setServiceFees] = useState(0)
    const { s: storetype, id, bei } = useQueryString()
    useEffect(() => {
        BrowserUtility.saveObj('qr-cart', { s: storetype, id, bei })
    }, [storetype, bei, id])
    const navigate = useNavigate()
    const { data: businessData, loading: businessLoading } =
        GetBusinessCategoryList(storetype, id)
    const menuCategoryList = useMemo(
        () => businessData?.MenuItems?.Menus?.filter(ele => ele.MenuType === 3),
        [businessData],
    )

    const addTicket = (item, qty) => {
        let tmp = items[item.ItemID] || {
            PerOrderLimit: item.PerOrderLimit,
            TotalLimit: item.TotalLimit,
            ProductId: item.ItemID,
            ProductItemId: item.ItemID,
            BasePrice: item.BasePrice,
            Qty: 0,
        }
        tmp.Qty += qty
        if (tmp.Qty === 0) {
            tmp = null
        } else if (tmp.Qty > tmp.TotalLimit) {
            tmp.Qty = tmp.TotalLimit
        }
        if (tmp && tmp.Qty > tmp.PerOrderLimit) {
            tmp.Qty = tmp.PerOrderLimit
        }
        setItems({ ...items, [item.ItemID]: tmp })
    }

    const clickOnNext = async formData => {
        if (!(formData.Email && formData.CellPhone)) {
            PushNotification('Please enter user info', NotificationStatus.error)
            return
        }
        const data = Object.values(items || {}).filter(d => d)
        if (data.length === 0) return
        const params = data.map(item => ({
            ProductId: item.ProductId,
            ProductItemId: item.ProductItemId,
            Qty: item.Qty,
        }))
        setLoading(true)
        try {
            const result = await BusinesService.addToCart(storetype, {
                CartInfo: params,
                ButtonType: 2,
                IsDelete: false,
                ButtonId: 0,
                UserInfo: {
                    Id: 0,
                    Email: formData.Email,
                    CellPhone: formData.CellPhone,
                },
            })
            const { ProductBucketId } = result.Result
            BrowserUtility.saveObj(
                `product-buy-now-${ProductBucketId}`,
                result?.Result,
            )
            setLoading(false)
            navigate(
                `/qr/cart-confirmation/${ProductBucketId}?storetype=${storetype}`,
            )
        } catch (error) {
            console.log(error)
            PushNotification(
                error?.error?.ErrorMessage || NotificationText.defaultError,
                NotificationStatus.error,
            )
            setLoading(false)
        }
    }
    const onNext = () => {
        setOpenUserModal(true)
    }

    const saveUser = formData => {
        if (formData) {
            const data = Object.values(items || {}).filter(d => d)
            if (data.length > 0) {
                clickOnNext(formData)
                setOpenUserModal(false)
            } else {
                PushNotification(
                    'Please select ticket',
                    NotificationStatus.error,
                )
            }
        } else {
            setOpenUserModal(false)
        }
    }

    useEffect(() => {
        const data = BrowserUtility.getObj('selected-items')
        setItems(data || {})
    }, [])
    useEffect(() => {
        const price = Object.values(items || {}).reduce(
            (a, b) => a + (b ? b.Qty * b.BasePrice : 0),
            0,
        )
        setTotalPrice(price)
        setServiceFees(0)
        BrowserUtility.saveObj('selected-items', items)
    }, [items])
    if (businessLoading) {
        return <BuyTicketSkeleton />
    }
    return (
        <TicketMain>
            <Form>
                <div className="d-flex flex-column justify-content-center">
                    <h3 div className="d-flex  justify-content-center">
                        {businessData.BusinessFullName}
                    </h3>
                    <h6 div className="d-flex  justify-content-center">
                        {businessData.FormattedAddress}
                    </h6>
                </div>
                <ModalContainer>
                    <div className="row buy-ticket-section">
                        {menuCategoryList?.[0]?.Items?.map(category => (
                            <div>
                                <h5 className="my-3">
                                    {category.CategoryName}
                                </h5>
                                <hr />
                                <div>
                                    {category.Items?.map(item => (
                                        <div
                                            key={`${item.ItemID}`}
                                            className="col-sm-12 col-md-6 px-0"
                                        >
                                            <ModalWrapper>
                                                <div className="item col-12">
                                                    <div className="d-flex flex-row justify-content-between mb-2">
                                                        <img
                                                            src={
                                                                item
                                                                    ?.Images?.[0]
                                                                    ?.url ||
                                                                'https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg'
                                                            }
                                                            alt=""
                                                            className="img"
                                                        />
                                                        {items[item.ItemID] && (
                                                            <div className="qty-selector">
                                                                <OuterBox>
                                                                    <Icon>
                                                                        <img
                                                                            src={
                                                                                MinusIcon
                                                                            }
                                                                            onClick={() => {
                                                                                addTicket(
                                                                                    item,
                                                                                    -1,
                                                                                )
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                    </Icon>
                                                                    <span>
                                                                        {' '}
                                                                        {
                                                                            items[
                                                                                item
                                                                                    .ItemID
                                                                            ]
                                                                                .Qty
                                                                        }{' '}
                                                                    </span>
                                                                    <Icon>
                                                                        <img
                                                                            src={
                                                                                AddIcon
                                                                            }
                                                                            onClick={() => {
                                                                                addTicket(
                                                                                    item,
                                                                                    1,
                                                                                )
                                                                            }}
                                                                            alt=""
                                                                        />
                                                                    </Icon>
                                                                </OuterBox>
                                                            </div>
                                                        )}
                                                        {!items[item.ItemID] &&
                                                            (item.TicketQty <=
                                                            0 ? (
                                                                <SoldTicket>
                                                                    <span>
                                                                        Sold Out
                                                                    </span>
                                                                </SoldTicket>
                                                            ) : (
                                                                <AddTicketBtn
                                                                    onClick={() =>
                                                                        addTicket(
                                                                            item,
                                                                            1,
                                                                        )
                                                                    }
                                                                >
                                                                    <span>
                                                                        Add
                                                                        Ticket
                                                                    </span>
                                                                </AddTicketBtn>
                                                            ))}
                                                    </div>
                                                    <div className="item-name">
                                                        {' '}
                                                        {item.ItemName}
                                                        {item.Description && (
                                                            <>
                                                                <span className="font-grey">
                                                                    &nbsp;
                                                                    {' - '}
                                                                    &nbsp;
                                                                    {
                                                                        item.Description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="item-price">
                                                        {' '}
                                                        ${' '}
                                                        <span>
                                                            {item.BasePrice}
                                                        </span>{' '}
                                                    </div>
                                                </div>
                                            </ModalWrapper>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <Loader loading={loading} />
                </ModalContainer>
                <TotalMain>
                    <TotalFees>
                        <Tag>Total fees</Tag>
                        <Amount>$ {totalPrice.toFixed(2)}</Amount>
                    </TotalFees>
                    <ServiceFees>
                        <Tag>Service fees</Tag>
                        <Amount>$ {serviceFees?.toFixed(2)}</Amount>
                    </ServiceFees>
                    <TotalAmount>
                        <Tag>Total amount</Tag>
                        <Amount>
                            $ {(totalPrice + serviceFees)?.toFixed(2)}
                        </Amount>
                    </TotalAmount>
                    <Btn className="row justify-content-center pt-2">
                        <div className="next-btn" onClick={onNext}>
                            <span>Next</span>
                        </div>
                    </Btn>
                </TotalMain>{' '}
            </Form>
            <EmailPhoneModal open={openUserModal} onClose={saveUser} />
        </TicketMain>
    )
}

const EmailPhoneModal = ({ open, onClose }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserSchema),
    })

    const submit = async formData => {
        onClose(formData)
    }
    return (
        <PopUpModal
            title="User Info"
            open={open}
            onSubmit={handleSubmit(submit)}
            onCancel={() => onClose(false)}
        >
            <Form>
                <div className="mt-2 mx-4">
                    <Form.Item className="col-12 col-sm-12 col-md-6">
                        <FormTextFormField
                            control={control}
                            name="Email"
                            placeholder="Email"
                            // label="Email"
                            label={
                                <span style={{ fontSize: '18px' }}>Email</span>
                            }
                            errors={errors?.Email}
                            defaultValue=""
                        />
                    </Form.Item>
                    <Form.Item className="col-12 col-sm-12 col-md-6">
                        <FormTextFormField
                            control={control}
                            name="CellPhone"
                            placeholder="+00 00000 000000"
                            // label="Phone Number"
                            label={
                                <span style={{ fontSize: '18px' }}>
                                    Phone Number
                                </span>
                            }
                            errors={errors?.CellPhone}
                            defaultValue=""
                        />
                    </Form.Item>
                    <div className="d-flex">
                        <span>*</span>
                        <div className="ms-1">
                            Please provide a valid email and phone number to
                            receive your order confirmation.
                        </div>
                    </div>
                </div>
            </Form>
        </PopUpModal>
    )
}

const BuyTicketSkeleton = () => {
    return (
        <TicketMain>
            <div className="heading-main">
                <div className="heading">Ticket Summary</div>
            </div>
            <ModalContainer>
                <div className="row buy-ticket-section">
                    <TicketSkeleton />
                    <TicketSkeleton />
                </div>
                <PriceSummarySkeleton />
            </ModalContainer>
        </TicketMain>
    )
}
const TicketSkeleton = () => {
    return (
        <div className="col-sm-12 col-md-6">
            <ModalWrapper>
                <div className="item">
                    <Skeleton.Input active />
                </div>
                <Skeleton.Button active />
            </ModalWrapper>
        </div>
    )
}
const PriceSummarySkeleton = () => {
    return (
        <TotalMain>
            <TotalFees>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </TotalFees>
            <ServiceFees>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </ServiceFees>
            <TotalAmount>
                <Skeleton.Input active />
                <Skeleton.Input active />
            </TotalAmount>
            <Btn className="row justify-content-center pt-2">
                <div className="back-btn">
                    <Skeleton.Input active />
                </div>
                <div className="next-btn">
                    <Skeleton.Input active />
                </div>
            </Btn>
        </TotalMain>
    )
}
