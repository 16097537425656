import { Form } from 'antd'
import { PopUpModal } from 'components'
import { FormTextFormField } from 'elements'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';

const AddPlaceSchema = yup.object().shape({
    TableName: yup.string().nullable(),
    TableLimit: yup.number().required('*Max Person Allowed is required').nullable().typeError('*Max Person Allowed is required'),
    ProviderEventId: yup.string(),
})

export const AddEditTableModal = ({ onSubmit, onCancel, open }) => {
    const {
        control,
        handleSubmit, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AddPlaceSchema),
    })
    useEffect(() => {
        reset({
            TableCount: null,
        })
    }, [open])
    return (
        <PopUpModal
            centered
            title="Add Table"
            open={open}
            onCancel={onCancel}
            onSubmit={handleSubmit(onSubmit)}
            width={400}
        >
            <Form className="row">
                <Form.Item className="col-12">
                    <FormTextFormField
                        control={control}
                        label="Pre Fix Name"
                        name="TableName"
                        placeholder="Enter Pre Fix Name"
                        errors={errors?.TableName}
                    />
                </Form.Item>
                <Form.Item className="col-12">
                    <FormTextFormField
                        control={control}
                        label="Max Person Allowed"
                        name="TableLimit"
                        placeholder="TableLimit"
                        errors={errors?.TableLimit}
                        type="number"
                    />
                </Form.Item>
            </Form>
        </PopUpModal>
    )
}