export const EventsData = [
  {
    id: 1,
    categoryId: 1,
    eventName: "event 123",
    placeId: 4,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Drishyam.jpg',
  },
  {
    id: 2,
    categoryId: 2,
    eventName: "event 123",
    placeId: 5,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/avatar.jpg',
  }, {
    id: 3,
    categoryId: 3,
    eventName: "event 123",
    placeId: 6,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Bhediya.jpg',
  },
  {
    id: 4,
    categoryId: 4,
    eventName: "event 123",
    placeId: 7,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/avatar.jpg',
  },
  {
    id: 5,
    categoryId: 5,
    eventName: "event 123",
    placeId: 8,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Drishyam.jpg',
  },
  {
    id: 6,
    categoryId: 6,
    eventName: "event 123",
    placeId: 9,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Bhediya.jpg',
  },
  {
    id: 7,
    categoryId: 7,
    eventName: "event 123",
    placeId: 3,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Drishyam.jpg',
  },
  {
    id: 8,
    categoryId: 8,
    eventName: "event 123",
    placeId: 9,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/avatar.jpg',
  },
  {
    id: 9,
    categoryId: 9,
    eventName: "event 123",
    placeId: 2,
    website: "www.abc.com",
    email: "abc@gmail.com",
    contactName: "Mr ABC",
    contactNumber: "12345",
    isFreeEvent: 1,
    isActive: 1,
    eventStartDateTime: "2022-01-01",
    eventEndDateTime: "2022-01-02",
    language: ["english", "hindi", "gujarati"],
    imageCategoryId: 1,
    eventImages: '/images/event/Bhediya.jpg',
  },
]

export const EventsLanguage = [
  {
    value: '1',
    label: 'English',
  },
  {
    value: '2',
    label: 'Hindi',
  },
  {
    value: '3',
    label: 'Gujarati',
  },
]

export const CountryCode = [
  {
    value: '+91',
    label: 'India',
  },
  {
    value: '+1',
    label: 'USA',
  },
  {
    value: '+93',
    label: 'Afghanistan',
  },
  {
    value: '+55',
    label: 'Brazil',
  },
]

export const EventCategoryEnum = {
  CashTicket: 3,
}