import React, { useMemo } from 'react'
import { Tabs } from 'antd';
import ManualScreen from './manual-screen';
import TicketFormScreen from './ticket-form';
import { GetSponsersTicketByEvent } from 'hooks';
import { useParams } from 'react-router-dom';

const SponsorScreens = () => {
  const { id: ProviderEventId } = useParams();
  const { data, setData, optionList } = GetSponsersTicketByEvent(ProviderEventId)
  const onChange = (key) => {
    console.log(key);
  };
  const items = useMemo(() => [
    {
      key: '1',
      label: 'Ticket center',
      children: <TicketFormScreen setData={setData} data={data} />,
    },
    {
      key: '2',
      label: 'Manual',
      children: <ManualScreen />,
    },
    // {
    //   key: '3',
    //   label: 'Upload',
    //   children: 'Content of Tab Pane 2',
    // },
  ], [data, optionList]);

  return (
    <div>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane items={items} onChange={onChange} />
    </div>
  )
}

export default SponsorScreens
