import { APIPath, CommonUtility } from 'utility'
import { BaseService } from './base'

class CouponTable {
    getCouponType() {
        return BaseService.get(`${APIPath.GTCouponType}`)
    }

    getCoupon(id, reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.get(
            `${APIPath.GTCouponTable}?CouponId=0&${data}`,
        )
    }

    addCoupon(reqData) {
        return BaseService.post(`${APIPath.GTCoupon}`, reqData)
    }

    updateCoupon(reqData) {
        return BaseService.post(`${APIPath.GTCoupon}/update`, reqData)
    }

    deleteCoupon(id) {
        return BaseService.post(`${APIPath.GTCouponRemove}?CouponId=${id}`)
    }
}

const CouponTableService = new CouponTable()
Object.freeze(CouponTableService)
export { CouponTableService }
