import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Popconfirm, Table } from 'antd'
import * as yup from 'yup'
import { FlexRowBetween, ProductContainer } from 'components'
import { FormSelectionField, OutlinedButton, PrimaryButton } from 'elements'
import { EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Trash } from 'phosphor-react'
import { GetCoupon, GetMyEvent, GetSponsersTicketByEvent } from 'hooks'
import { AssignCouponService } from 'utility'
import { GetAssignCoupon } from 'hooks/assigncoupon'

const EventCouponSchema = yup.object().shape({
    EventId: yup.string().required('*Event List is required').nullable(),
    CouponId: yup.string().required('*Coupon List is required').nullable(),
    EventTicketTypeId: yup.string().required('*Event Ticket Type is required').nullable(),
})

const EditIcon = styled(EditOutlined)``

export const AssignCoupon = () => {
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false);
    const {
        data: couponData,
        total,
        filter,
        pageChanged,
    } = GetCoupon()
    const { data: events } = GetMyEvent()

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(EventCouponSchema),
    })

    const {
        data: assignedCoupons,
        loading: tableLoading,
        refetch,
    } = GetAssignCoupon()

    const eventId = watch("EventId")
    const {
        optionList: eventTicketTypeList,
        filterChanged,
    } = GetSponsersTicketByEvent(eventId)

    useEffect(() => {
        filterChanged({
            EventId: eventId,
        })
    }, [eventId])

    const submit = async formData => {
        console.log('Form data:', formData)
        setLoading(true)
        try {
            await AssignCouponService.addAssignCoupon(formData)
            refetch()
        } catch (error) {
            console.error('Failed to assign coupon:', error)
        } finally {
            setLoading(false)
        }
    }

    const onEdit = record => {
        reset(record)
    }
    const deleteCoupon = async record => {
        setDeleting(true);
        try {
            const { ProviderEventId, CouponId, CreatedBy } = record;
            await AssignCouponService.deleteAssignedCoupon(ProviderEventId, CouponId, CreatedBy);
            refetch();
        } catch (error) {
            console.error('Failed to delete coupon:', error);
        } finally {
            setDeleting(false);
        }
    };

    useEffect(() => {
        reset({
            EventId: '',
            CouponId: '',
            EventTicketTypeId: '',
        })
    }, [])

    const couponOptions =
        couponData?.map(coupon => ({
            label: coupon.CouponCode,
            value: coupon.CouponId,
        })) || []

    const eventOptions =
        events?.map(event => ({
            label: event.EventName,
            value: event.ProviderEventId,
        })) || []

    const columns = [
        {
            title: 'Coupon',
            dataIndex: 'CouponCode',
            key: 'CouponId',
        },
        {
            title: 'Event',
            dataIndex: 'EventName',
            key: 'EventId',
        },
        {
            title: 'Event Ticket Type',
            dataIndex: 'EventTicketTypeName',
            key: 'EventTicketTypeName',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <EditIcon
                        className="mx-2 pointer"
                        onClick={() => onEdit(record)}
                        size={24}
                    />
                    <Popconfirm
                        title="Delete the entry"
                        description="Are you sure to delete this entry?"
                        onConfirm={() => deleteCoupon(record)}
                        okText="Yes"
                        cancelButtonProps={<div />}
                        cancelText="No"
                    >
                        <Trash className="mx-2 pointer" size={20} />
                    </Popconfirm>
                </>
            ),
        },
    ]

    return (
        <div className="position-relative row d-flex justify-content-center mb-3 mt-3">
            <div className="col-12 ml-2 mr-2 box-layout">
                <ProductContainer>
                    <Form className="">
                        <div className="row">
                            <FlexRowBetween>
                                <h1 className="mt-0">Assign Coupon Details</h1>
                            </FlexRowBetween>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormSelectionField
                                    control={control}
                                    name="CouponId"
                                    placeholder="Coupon List"
                                    label="Coupon List"
                                    errors={errors?.CouponId}
                                    defaultValue=""
                                    options={couponOptions}
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormSelectionField
                                    control={control}
                                    name="EventId"
                                    placeholder="Event List"
                                    label="Event List"
                                    errors={errors?.EventId}
                                    defaultValue=""
                                    options={eventOptions}
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormSelectionField
                                    control={control}
                                    name="EventTicketTypeId"
                                    placeholder="Event Ticket Type"
                                    label="Event Ticket Type"
                                    errors={errors?.EventTicketTypeId}
                                    defaultValue=""
                                    options={eventTicketTypeList}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </div>
                        <p className="col d-flex justify-content-end mt-3">
                            <OutlinedButton
                                className="mx-2"
                                fluid="true"
                                onClick={() => reset()}
                                type="button"
                            >
                                Reset
                            </OutlinedButton>
                            <PrimaryButton
                                className=""
                                fluid="true"
                                onClick={handleSubmit(submit)}
                                type="submit"
                                loading={loading}
                            >
                                Save
                            </PrimaryButton>
                        </p>
                    </Form>
                    <Table
                        dataSource={assignedCoupons}
                        columns={columns}
                        rowKey="EventCouponId"
                        loading={tableLoading || deleting}
                        pagination={{
                            pageSize: filter.pageSize,
                            current: filter.pageNumber,
                            total,
                            onChange: pageChanged,
                        }}
                    />
                </ProductContainer>
            </div>
        </div>
    )
}
