import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Popconfirm, Table } from 'antd'
import * as yup from 'yup'
import { FlexRowBetween, ProductContainer, PushNotification } from 'components'
import {
    FormCheckBox,
    FormCheckBoxField,
    FormDateField,
    FormSelectionField,
    FormTextFormField,
    OutlinedButton,
    PrimaryButton,
} from 'elements'
import { EditOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Trash } from 'phosphor-react'
import { GetCoupon, GetCouponTypeList } from 'hooks'
import {
    CouponTableService,
    NotificationStatus,
    NotificationText,
} from 'utility'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const CouponSchema = yup.object().shape({
    CouponId: yup.number(),
    CouponCode: yup.string().required('*CouponCode is required'),
    StartDate: yup.string().required('*StartDate is required'),
    EndDate: yup.string().required('*EndDate is required'),
    CouponTypeId: yup.number().required('*CouponTypeId is required'),
    MinTicket: yup.string().required('*MinTicket is required'),
    LimitInCount: yup
        .number()
        .required('*LimitInCount is required')
        .typeError('*LimitInCount is required'),
})
const EditIcon = styled(EditOutlined)``

export const Coupon = () => {
    const [loading, setLoading] = React.useState(false)
    const { data, refetch, setData, total, filter, pageChanged } = GetCoupon()
    const [proccessing, setProccessing] = useState('')
    const { options: couponTypeList } = GetCouponTypeList()
    const [isPersantage, setisPersantage] = useState(false)
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CouponSchema),
        defaultValues: {
            CouponId: 0,
            IsActive: true,
        },
    })

    const submit = async formData => {
        console.log('Form data:', formData)
        setLoading(true)
        try {
            const reqData = {
                ...formData,
                CouponId: formData.CouponId || 0,
                CouponCode: formData.CouponCode || '',
                StartDate: formData.StartDate || '',
                EndDate: formData.EndDate || '',
                IsActive: formData.IsActive || '',
                CouponTypeId: formData.CouponTypeId || 0,
                FlatDiscount: formData.FlatDiscount || 0,
                FlatPercentage: formData.FlatPercentage || 0,
                MinTicket: formData.MinTicket || '',
                LimitInCount: formData.LimitInCount || 0,
            }
            if (formData.CouponId) {
                await CouponTableService.updateCoupon(reqData)
            } else {
                await CouponTableService.addCoupon(reqData)
            }
            reset({ CouponCode: '' })
            PushNotification(
                NotificationText.CouponAdded,
                NotificationStatus.success,
            )
            refetch()
        } catch (error) {
            PushNotification(
                NotificationText.defaultError,
                NotificationStatus.error,
            )
        } finally {
            setLoading(false)
        }
    }

    const deleteCoupon = record => {
        try {
            setProccessing(true)
            CouponTableService.deleteCoupon(record.CouponId)
            setData(data.filter(ele => ele.CouponId !== record.CouponId))
        } catch (error) {
            console.log(error)
        } finally {
            setProccessing(false)
        }
    }

    const onEdit = record => {
        reset({
            ...record,
            StartDate: dayjs(record.StartDate) || '',
            EndDate: dayjs(record.EndDate) || '',
        })
    }

    useEffect(() => {
        reset({
            IsActive: true,
        })
    }, [])

    const handleCheckboxChange = () => {
        setisPersantage(!isPersantage)
        setValue('FlatPercentage', '')
        setValue('FlatDiscount', '')
    }
    const columns = [
        {
            title: 'CouponId',
            dataIndex: 'CouponId',
            key: 'CouponId',
        },
        {
            title: 'CouponCode',
            dataIndex: 'CouponCode',
            key: 'CouponCode',
        },
        {
            title: 'LimitInCount',
            dataIndex: 'LimitInCount',
            key: 'LimitInCount',
        },
        {
            title: 'FlatDiscount',
            dataIndex: 'FlatDiscount',
            key: 'FlatDiscount',
        },
        {
            title: 'FlatPercentage',
            dataIndex: 'FlatPercentage',
            key: 'FlatPercentage',
        },
        {
            title: 'MinTicket',
            dataIndex: 'MinTicket',
            key: 'MinTicket',
        },
        {
            title: 'StartDate',
            dataIndex: 'StartDate',
            key: 'StartDate',
            render: StartDate => {
                const date = dayjs(StartDate).format('DD/MM/YYYY')

                return (
                    <span
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            maxWidth: '200px',
                        }}
                    >
                        {date}
                    </span>
                )
            },
        },
        {
            title: 'EndDate',
            dataIndex: 'EndDate',
            key: 'EndDate',
            render: StartDate => {
                const date = dayjs(StartDate).format('DD/MM/YYYY')

                return (
                    <span
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            maxWidth: '200px',
                        }}
                    >
                        {date}
                    </span>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <EditIcon
                        className="mx-2 pointer"
                        onClick={() => onEdit(record)}
                        size={24}
                    />
                    <Popconfirm
                        title="Delete the product"
                        description="Are you sure to revoke this product?"
                        onConfirm={() => deleteCoupon(record)}
                        okText="Yes"
                        cancelButtonProps={<div />}
                        cancelText="No"
                    >
                        <Trash className="mx-2 pointer" size={20} />
                    </Popconfirm>
                </>
            ),
        },
    ]

    return (
        <div className="position-relative row d-flex justify-content-center mb-3 mt-3">
            <div className="col-12 ml-2 mr-2 box-layout">
                <ProductContainer>
                    <Form className="">
                        <div className="row">
                            <FlexRowBetween>
                                <h1 className="mt-0">Coupon Details</h1>
                            </FlexRowBetween>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="CouponCode"
                                    placeholder="Coupon Code"
                                    label="Coupon Code"
                                    errors={errors?.CouponCode}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormSelectionField
                                    control={control}
                                    name="CouponTypeId"
                                    placeholder="Coupon Type Id"
                                    label="Coupon Type Id"
                                    errors={errors?.CouponTypeId}
                                    defaultValue=""
                                    options={couponTypeList}
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormDateField
                                    control={control}
                                    name="StartDate"
                                    placeholder="Start Date"
                                    label="Start Date"
                                    errors={errors?.StartDate}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormDateField
                                    control={control}
                                    name="EndDate"
                                    placeholder="End Date"
                                    label="End Date"
                                    errors={errors?.EndDate}
                                    defaultValue=""
                                    disabled={loading}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormCheckBox
                                    label="Flat Discount"
                                    checked={!isPersantage}
                                    onChange={() =>
                                        handleCheckboxChange(!isPersantage)
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="FlatDiscount"
                                    placeholder="Flat Discount"
                                    label="Flat Discount"
                                    errors={errors?.FlatDiscount}
                                    defaultValue=""
                                    type="number"
                                    disabled={isPersantage}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormCheckBox
                                    label="Flat Persantage"
                                    checked={isPersantage}
                                    onChange={() =>
                                        handleCheckboxChange(!isPersantage)
                                    }
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="FlatPercentage"
                                    placeholder="Flat Percentage"
                                    label="Flat Percentage"
                                    errors={errors?.FlatPercentage}
                                    defaultValue=""
                                    // disabled={loading}
                                    type="number"
                                    disabled={!isPersantage}
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="MinTicket"
                                    placeholder="Minimum Tickets Required"
                                    label="Minimum Tickets Required"
                                    errors={errors?.MinTicket}
                                    defaultValue=""
                                    disabled={loading}
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormTextFormField
                                    control={control}
                                    name="LimitInCount"
                                    placeholder="Number of Coupon Codes"
                                    label="Number of Coupon Codes"
                                    errors={errors?.LimitInCount}
                                    defaultValue=""
                                    disabled={loading}
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item className="col-12 col-sm-12 col-md-6">
                                <FormCheckBoxField
                                    control={control}
                                    name="IsActive"
                                    placeholder="Active"
                                    label="Is Active"
                                    errors={errors?.IsActive}
                                />
                            </Form.Item>
                        </div>
                        <p className="col d-flex justify-content-end mt-3">
                            <OutlinedButton
                                className="mx-2"
                                fluid="true"
                                onClick={() => reset()}
                                type="button"
                            >
                                Reset
                            </OutlinedButton>
                            <PrimaryButton
                                className=""
                                fluid="true"
                                onClick={handleSubmit(submit)}
                                type="submit"
                                loading={loading || proccessing}
                            >
                                Save
                            </PrimaryButton>
                        </p>
                    </Form>
                    <Table
                        dataSource={data}
                        columns={columns}
                        rowKey="CouponId"
                        loading={loading}
                        pagination={{
                            pageSize: filter.pageSize,
                            current: filter.pageNumber,
                            total,
                            onChange: pageChanged,
                        }}
                    />
                </ProductContainer>
            </div>
        </div>
    )
}

export default Coupon
