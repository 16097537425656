import { APIPath, CommonUtility } from 'utility'
import { BaseService } from './base'

class Product {
    getProduct(id, reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.get(
            `${APIPath.GTProductMember}?teamdId=${id}&${data}`,
        )
    }

    getProductById(reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.get(
            `${APIPath.GTProductByid}?${data}`,
        )
    }

    addProduct(reqData) {
        return BaseService.post(`${APIPath.GTProduct}`, reqData)
    }

    deleteProduct(id) {
        return BaseService.post(`${APIPath.GTProductRemove}?productId=${id}`)
    }

    deletePhoto(id) {
        return BaseService.post(`${APIPath.GTProductPhotoRemove}?imageId=${id}`)
    }
}

const ProductService = new Product()
Object.freeze(ProductService)
export { ProductService }
