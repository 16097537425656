import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { DangerButton } from 'elements'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { BrowserUtility } from 'utility'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    .green {
        path {
            fill: green;
        }
    }
    .red {
        path {
            fill: red;
        }
    }
    .main-img {
        width: 120px;
        height: 120px;
    }
`

const Image = styled.img`
    max-width: 50%;
    height: auto;
`

const BoldText = styled.p`
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 27.32px;
    margin-top: 20px;
    margin-bottom: 10px;
`

const RegularText = styled.p`
    color: #8a8a8a;
    font-size: 14px;
`

const EmailBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 16px;
    color: #333;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 200px) and (max-width: 480px) {
        width: 100%;
    }
    @media (min-width: 481px) and (max-width: 768px) {
        width: 100%;
    }
    @media (min-width: 769px) and (max-width: 1000px) {
        width: 90%;
    }
    @media (min-width: 1001px) and (max-width: 1200px) {
        width: 100%;
    }
`

const Label = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`

const ItemList = styled.div`
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }
`

const TotalBox = styled.div`
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
`

export const TicketSucessScreen = () => {
    const navigate = useNavigate()
    const {
        state: { orderRes },
    } = useLocation()

    useEffect(() => {
        BrowserUtility.remove('selected-items')
    }, [])

    const cartUrlData = JSON.parse(BrowserUtility.get('qr-cart'))

    // Calculate SubTotal
    const items = useMemo(
        () => orderRes?.Result?.OrderSummary?.OrderItems?.item || [],
        [orderRes],
    )
    const subTotal = items.reduce((acc, item) => acc + item.Price * item.Qty, 0)
    const taxAmount = items.reduce(
        (acc, item) => acc + (item.TaxAmount || 0),
        0,
    )
    const CurrencyCode = orderRes?.Result?.OrderSummary.OrderItems.CurrencyCode

    return (
        <Container className="mt-5">
            <FontAwesomeIcon
                icon={
                    orderRes?.Result?.FontName === 'circle-check'
                        ? faCircleCheck
                        : faCircleXmark
                }
                className={`main-img ${
                    orderRes?.Result?.FontName === 'circle-check'
                        ? 'green'
                        : 'red'
                }`}
            />
            <h6 className="mt-3">
                {orderRes?.Result?.OrderNumberLabel} -{' '}
                {orderRes?.Result?.OrderNumber}
            </h6>
            <BoldText>{orderRes?.Result?.OrderMessage}</BoldText>
            <RegularText>
                {orderRes?.Result?.OrderSubLabel}
                {orderRes?.Result?.OrderSubTitle}
            </RegularText>
            <EmailBox className="mb-3">
                {' '}
                <Label>
                    <strong>Buyer :</strong>
                    <span>{orderRes?.Result?.OrderSummary?.Email}</span>
                </Label>
            </EmailBox>
            <EmailBox className="mb-3">
                {/* <Label>
                    <strong>Order Number :</strong>
                    <span>{orderRes?.Result?.OrderSummary?.OrderNumber}</span>
                </Label> */}
                <ItemList>
                    {items?.length > 0 ? (
                        items?.map((item, index) => (
                            <div key={index}>
                                <span>
                                    <strong>
                                        {item.Name} ( Qty: {item.Qty} ) :
                                    </strong>
                                </span>
                                <span>
                                    {item.CurrencyCode}
                                    {item.Price}
                                </span>
                            </div>
                        ))
                    ) : (
                        <span>No items found</span>
                    )}
                </ItemList>
            </EmailBox>
            <EmailBox>
                <TotalBox>
                    <div>
                        <strong>SubTotal :</strong>
                        <span>
                            {CurrencyCode}
                            {subTotal.toFixed(2)}
                        </span>
                    </div>
                    <div>
                        <strong>Tax :</strong>
                        <span>
                            {' '}
                            {CurrencyCode}
                            {taxAmount.toFixed(2)}
                        </span>
                    </div>
                    <div>
                        <strong>Total :</strong>
                        <span>
                            {' '}
                            {CurrencyCode}
                            {(taxAmount + subTotal).toFixed(2)}
                        </span>
                    </div>
                </TotalBox>
            </EmailBox>
            <Image
                className="mt-3"
                src={orderRes?.Result?.QRCode}
                alt="Success"
            />
            <DangerButton
                onClick={() =>
                    // navigate(
                    //     `/qr/cart?id=${cartUrlData.id}&storetype=${cartUrlData.storetype}`,
                    // )
                    // navigate(
                    //     `/qr/cart?id=${cartUrlData.id}&s=${cartUrlData.s}&bei=${cartUrlData.bei}`,
                    // )
                    navigate(
                        `/qr/cart?id=${cartUrlData.id}&s=${cartUrlData.s}&bei=${cartUrlData.bei}`,
                    )
                }
                className="mt-5"
            >
                Buy More Items
            </DangerButton>
        </Container>
    )
}
