import { Divider, Form, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormTextFormField, PrimaryButton, OutlinedButton } from 'elements'
import {
    DangerText,
    FlexRow,
    FlexRowBetween,
    PushNotification,
} from 'components'
import { Trash } from 'phosphor-react'
import { GetRedistributeList, useQueryString } from 'hooks'
import {
    AuthPopup,
    NotificationStatus,
    NotificationText,
    RedistributeTicketService,
} from 'utility'
import { useLayout } from 'layout'
import { useAuth } from 'context'

const RedistributeContainer = styled.div`
    .card-box {
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 10px;
    }
    .note-text {
        font-size: 14px;
        text-align: justify;
        color: #a6a6a6;
        padding: 0px 10px;
    }
`

const RedistributeSchema = yup.object().shape({
    RedistributeArray: yup.array().of(
        yup.object().shape({
            RecipientEmail: yup
                .string()
                .required('Email is required')
                .email('Email must be valid'),
            RedistributedTickets: yup
                .number()
                .required('Tickets is required')
                .typeError('Tickets must be number')
                .nullable(),
        }),
    ),
})
export const RedistributeScreen = () => {
    const [error, setError] = useState('')
    const [proccessing, setProcessing] = useState('')
    const { redistibutionticket } = useQueryString()
    const { data, refetch } = GetRedistributeList(redistibutionticket)
    const { toggleModal } = useLayout()
    const { isAuthenticated } = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(RedistributeSchema),
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'RedistributeArray',
    })
    const addNew = () => {
        append({ Id: '', RecipientEmail: '', RedistributedTickets: '' })
    }
    useEffect(() => {
        if (!data.length) {
            addNew({})
        }
    }, [data])
    const removeItem = async index => {
        remove(index)
    }
    const submit = async formData => {
        if (isAuthenticated) {
            const limitExceed = formData.RedistributeArray.reduce(
                (a, b) => a + b.RedistributedTickets,
                0,
            )
            if (limitExceed > data?.[0]?.TotalTicketAvailable) {
                PushNotification(
                    NotificationText.LimitExceed,
                    NotificationStatus.error,
                )
                return
            }
            try {
                setProcessing('proccessing')
                setError('')
                const list = formData.RedistributeArray.map(ele => ({
                    ...ele,
                    RedistributionUId: redistibutionticket,
                    RecipientFullName: '',
                    Id: undefined,
                }))
                await RedistributeTicketService.add(list)
                refetch()
            } catch (error) {
                console.log(error)
            } finally {
                setProcessing('')
            }
        } else {
            toggleModal(AuthPopup.login)
        }
    }
    const columns = useMemo(
        () => [
            {
                title: 'RecipientEmail',
                dataIndex: 'RecipientEmail',
                key: 'RecipientEmail',
            },
            {
                title: 'TicketType',
                dataIndex: 'TicketType',
                key: 'TicketType',
            },
            {
                title: 'RedistributedTickets',
                dataIndex: 'RedistributedTickets',
                key: 'RedistributedTickets',
            },
        ],
        [data],
    )
    return (
        <RedistributeContainer className="container mt-5">
            <div className="card-box  p-4">
                <h5>
                    <FlexRowBetween>
                        <span>Redistribute Tickets</span>
                        <span>
                            Total Available Tickets:{' '}
                            {data?.[0]?.TotalTicketAvailable}
                        </span>
                    </FlexRowBetween>
                </h5>
                <Divider className="mt-0" />
                <Form className="" initialValues={{ Email: '', Password: '' }}>
                    {fields.map((_, index) => (
                        <FlexRow className="row d-flex">
                            <Form.Item className="col-6">
                                <FormTextFormField
                                    control={control}
                                    label="Email Address"
                                    name={`RedistributeArray.${index}.RecipientEmail`}
                                    placeholder="Email Address"
                                    errors={
                                        errors?.RedistributeArray?.[index]
                                            ?.RecipientEmail
                                    }
                                    defaultValue=""
                                />
                            </Form.Item>
                            <Form.Item className="col-6">
                                <FlexRow className="align-items-end col-12">
                                    <div className="col">
                                        <FormTextFormField
                                            control={control}
                                            label="Ticket"
                                            name={`RedistributeArray.${index}.RedistributedTickets`}
                                            placeholder="Ticket"
                                            errors={
                                                errors?.RedistributeArray?.[
                                                    index
                                                ]?.RedistributedTickets
                                            }
                                            type="number"
                                        />
                                    </div>
                                    {index === 0 ? (
                                        <PrimaryButton
                                            className=" ms-2"
                                            onClick={addNew}
                                        >
                                            Add New Email
                                        </PrimaryButton>
                                    ) : (
                                        <OutlinedButton
                                            className=" ms-2"
                                            onClick={() => removeItem(index)}
                                        >
                                            <Trash />
                                        </OutlinedButton>
                                    )}
                                </FlexRow>
                            </Form.Item>
                        </FlexRow>
                    ))}
                    {error && (
                        <Form.Item>
                            <DangerText>{error}</DangerText>
                        </Form.Item>
                    )}
                    <FlexRow className="justify-content-end d-flex mb-3">
                        <div className="2">
                            <PrimaryButton
                                className=""
                                loading={proccessing}
                                block
                                type="submit"
                                onClick={handleSubmit(submit)}
                            >
                                Submit
                            </PrimaryButton>
                        </div>
                    </FlexRow>
                    <Table
                        columns={columns}
                        keyField="EventTicketId"
                        dataSource={data || []}
                        size="small"
                    />
                </Form>
            </div>
            <p className=" card-box  p-4 mt-3 note-text">
                Once you redistribute these complimentary tickets to your
                friends and family, the process is final. You will not be able
                to recall or reallocate the tickets after distribution.
            </p>
        </RedistributeContainer>
    )
}
