import { APIPath, CommonUtility } from 'utility'
import { BaseService } from './base'

class PDF {
    sendPDF(reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.post(`${APIPath.sendPDF}?${data}`)
    }
}

const PDFService = new PDF()
Object.freeze(PDFService)
export { PDFService }